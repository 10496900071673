import React, { useState, useEffect } from "react";
import {Box, CircularProgress, IconButton, Typography} from "@mui/material";
import {ArrowBack, ArrowForward} from "@mui/icons-material";
import api from "../api";
import {useLanguage} from "../LanguageContext";

const YandexDiskImages = ({ filePaths,pathcat }) => {
    const [resolvedLinks, setResolvedLinks] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isProcessingComplete, setIsProcessingComplete] = useState(false);
    const { t } = useLanguage();

    const getFileName = (url) => {
        // Разделяем URL по символу '/'
        const parts = url.split('/');
        // Возвращаем последний элемент массива — имя файла
        return parts[parts.length - 1];
    };

    const fetchImageLinks = async () => {
        try {
            const finalLinks = await Promise.all(
                filePaths.map(async (path) => {
                    const apiUrl = `${pathcat}${getFileName(path)}`;
                    try {
                        const response = await api.get(`/api/file?f=${apiUrl}`);

                        // Если ответ успешен, возвращаем данные
                        if (response.status === 200) {
                            return response.data;  // Возвращаем только данные из ответа
                        } else {
                            throw new Error("Ошибка получения файла: " + response.status);
                        }
                    } catch (err) {
                        // Логируем ошибку, если что-то пошло не так
                        console.error("Ошибка при получении файла:", err);
                        return null;  // Возвращаем null в случае ошибки
                    }
                })
            );

            // Фильтруем null значения из finalLinks, чтобы добавить только успешные данные
            const successfulLinks = finalLinks.filter((link) => link !== null);

            // Обновляем состояние только с успешными ссылками
            setResolvedLinks(successfulLinks);
            setIsProcessingComplete(true);
        } catch (error) {
            // Логируем общую ошибку при загрузке ссылок
            console.error("Общая ошибка при загрузке ссылок:", error);
            setIsProcessingComplete(true);
        }
    };

    useEffect(() => {
            fetchImageLinks();
    }, [filePaths]);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? resolvedLinks.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === resolvedLinks.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <Box textAlign="center">
            {resolvedLinks.length === 0 && !isProcessingComplete ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="20vh"
                >
                    {/* Чёрный CircularProgress */}
                    <CircularProgress style={{ color: "black" }} />
                    {/* Подпись под лоадером */}
                    <Typography variant="h6" style={{ marginTop: 16 }}>
                        Загрузка изображений
                    </Typography>
                </Box>
            ) : resolvedLinks.length === 0 && isProcessingComplete ? (
                <Typography variant="h6">Изображений нет</Typography>
            ) : (
                <Box position="relative" maxWidth="600px" margin="auto">
                    {/* Картинка */}
                    <Box>
                        {resolvedLinks && resolvedLinks[currentIndex] && resolvedLinks[currentIndex].ContentType && resolvedLinks[currentIndex].Buffer && (<img
                            src={ `data:${resolvedLinks[currentIndex].ContentType};base64,${resolvedLinks[currentIndex].Buffer}` }
                            alt={`${t('image')} ${currentIndex + 1}`}
                            style={{
                                width: "100%",
                                maxHeight: "400px",
                                objectFit: "contain",
                            }}
                        />)}

                        <Typography variant="caption" display="block" marginTop={1}>
                            {`${t('image')} ${currentIndex + 1} ${t('of')} ${resolvedLinks.length}`}
                        </Typography>
                    </Box>

                    {/* Кнопки управления */}
                    <IconButton
                        onClick={handlePrev}
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "0",
                            transform: "translateY(-50%)",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "#fff",
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <IconButton
                        onClick={handleNext}
                        style={{
                            position: "absolute",
                            top: "50%",
                            right: "0",
                            transform: "translateY(-50%)",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "#fff",
                        }}
                    >
                        <ArrowForward />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export default YandexDiskImages;
