import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../api";
import { useNotification } from "../NotificationContext";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useLanguage } from "../LanguageContext";

export default function ConfirmRegistration() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const notify = useNotification();
    const { t } = useLanguage();

    // Функция для извлечения токена из URL
    const getTokenFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get("token");
    };

    const token = getTokenFromUrl();

    useEffect(() => {
        if (!token) {
            setError(t("invalid_token"));
        }
    }, [token, t]);

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            setError(t("password_mismatch"));
            return;
        }

        if (password.length < 6) {
            setError(t("password_length_error"));
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const { status,data } = await api.post(
                "/api/confirm",
                { token, password },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (status === 200) {
                setSuccess(true);
                notify(t("registration_confirmed"), "success");
                setIsLoading(false);
                navigate("/login"); // Перенаправляем пользователя на страницу логина
            } else {
                setError(t("confirmation_failed"));
                setIsLoading(false);
            }
        } catch (err) {
            setError(t("confirmation_failed"));
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                {t("confirm_registration")}
            </Typography>

            {error && (
                <Typography variant="body1" color="error" gutterBottom>
                    {error}
                </Typography>
            )}

            {success ? (
                <Typography variant="body1" color="success" gutterBottom>
                    {t("registration_successful_message")}
                </Typography>
            ) : (
                <>
                    <TextField
                        label={t("password")}
                        type="password"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        label={t("confirm_password")}
                        type="password"
                        fullWidth
                        margin="normal"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        sx={{ marginTop: 2 }}
                    >
                        {isLoading ? t("submitting") : t("confirm_registration")}
                    </Button>
                </>
            )}
        </Box>
    );
}
