import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import Login from "./pages/Login";
import ExpertDashboard from "./pages/ExpertDashboard";
import AdminDashboard from "./pages/AdminDashboard";
//import { Box, AppBar, Toolbar, Button } from "@mui/material";
import ProtectedRoute from "./ProtectedRoute";
import Menu from "./Menu";
import {LanguageProvider} from "./LanguageContext";
import SeasonManager from "./pages/SeasonManager";
import ExpertManager from "./pages/ExpertManager";
import VotingObjectManager from "./pages/VotingObjectManager";
import ConfirmRegistration from "./pages/ConfirmRegistration";
import CategoryManager from "./pages/CategoryManager";
import ResetPassword from "./pages/ResetPassword";

function App() {
  return (
      <Router>
          <LanguageProvider>
          <AuthProvider>
        {/*<AppBar position="static">*/}
        {/*  <Toolbar>*/}
        {/*    <Button color="inherit" href="/dashboard">Dashboard</Button>*/}
        {/*    <Button color="inherit" href="/admin">Admin</Button>*/}
        {/*    <Button color="inherit" href="/login">Login</Button>*/}
        {/*  </Toolbar>*/}
        {/*</AppBar>*/}
          <Menu />
        {/*<Box sx={{ mt: 2 }}>*/}
          <Routes>
            <Route path="/users/confirm" element={ <ConfirmRegistration /> } />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
                path="/dashboard"
                element={<ProtectedRoute>
                            <ExpertDashboard />
                         </ProtectedRoute>} />
            <Route
                path="/admin"
                element={<ProtectedRoute roles={["admin"]}>
                            <AdminDashboard />
                         </ProtectedRoute>} />
              <Route
                  path="/admin/seasons"
                  element={<ProtectedRoute roles={["admin"]}>
                      <SeasonManager />
                  </ProtectedRoute>} />
              <Route
                  path="/admin/categories"
                  element={<ProtectedRoute roles={["admin"]}>
                      <CategoryManager />
                  </ProtectedRoute>} />
              <Route
                  path="/admin/objects"
                  element={<ProtectedRoute roles={["admin"]}>
                      <VotingObjectManager />
                  </ProtectedRoute>} />
              <Route
                  path="/admin/experts"
                  element={<ProtectedRoute roles={["admin"]}>
                      <ExpertManager />
                  </ProtectedRoute>} />
            <Route path="/unauthorized" element={<h1>Unauthorized</h1>} />
          </Routes>
        {/*</Box>*/}
          </AuthProvider>
          </LanguageProvider>
      </Router>
  );
}

export default App;
