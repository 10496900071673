import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

export default function ProtectedRoute({ children, roles = [] }) {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to="/login" />;
    }

    if (roles.length && !roles.some(role => user.roles.includes(role))) {
        return <Navigate to="/unauthorized" />;
    }

    return children;
}