import React, {useEffect, useState} from "react";
import { useAuth } from "./AuthContext";
import { AppBar, Button, Toolbar, Menu, MenuItem, Box, Drawer, List, ListItem, ListItemText, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import LanguageSelector from "./LanguageSelector";
import { useLanguage } from './LanguageContext';
import theme from './theme';

export default function MenuBar() {
    const { user, logout } = useAuth();
    const { t } = useLanguage();
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        // Если пользователь не авторизован и находится на корневом пути, перенаправляем его на /login
        if (!user && window.location.pathname === '/') {
            window.location.href = '/login';
        } else if (user && window.location.pathname === '/') {
            window.location.href = '/dashboard';
        }
    }, [user]);

    const handleAdminMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAdminMenuClose = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = (open) => (event) => {
        setDrawerOpen(open);
    };

    const navigateTo = (path) => {
        setDrawerOpen(false);
        window.location.href = path; // Можно использовать useNavigate() если используется React Router
    };

    return (
        <AppBar position="static">
            <Toolbar  sx={{ backgroundColor: theme.palette.menu.main }}>
                <IconButton edge="start" color="inherit" onClick={() => navigateTo('/')} sx={{ display: { xs:'none',sm: 'none', md: 'block' },mr: 2 }}>
                    <img src={t('logoI')} alt="Logo" style={{ width: '80px' }} />
                </IconButton>
                {/* Отображение иконки гамбургера для мобильных устройств */}
                <IconButton color="inherit" onClick={toggleDrawer(true)}
                            sx={{ display: { xs:'block',sm: 'block', md: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Box
                    sx={{ display: { xs:'none',sm: 'none', md: 'block' } }}
                >
                <Button color="inherit" href="/dashboard">
                    {t('panel_expert')}
                </Button>

                {user?.roles.includes("admin") && (
                    <>
                        <Button
                            color="inherit"
                            onClick={handleAdminMenuOpen}
                        >
                            {t('admin')}
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleAdminMenuClose}

                        >
                            <MenuItem onClick={() => navigateTo('/admin')}>
                                {t('statistics')}
                            </MenuItem>
                            <MenuItem onClick={() => navigateTo('/admin/seasons')}>
                                {t('manage_seasons')}
                            </MenuItem>
                            <MenuItem onClick={() => navigateTo('/admin/categories')}>
                                {t('manage_categories')}
                            </MenuItem>
                            <MenuItem onClick={() => navigateTo('/admin/objects')}>
                                {t('manage_objects')}
                            </MenuItem>
                            <MenuItem onClick={() => navigateTo('/admin/experts')}>
                                {t('manage_experts')}
                            </MenuItem>
                        </Menu>
                    </>
                )}
                </Box>
                {/* Разделитель для размещения элементов справа */}
                <Box sx={{ flexGrow: 1 }} />

                {/* Элементы справа */}
                {user ? (
                    <Button color="inherit" onClick={logout}>
                        {t('logout')} ({user.email})
                    </Button>
                ) : (
                    <Button color="inherit" href="/login">
                        {t('login')}
                    </Button>
                )}

                <LanguageSelector />
            </Toolbar>

            {/* Drawer для отображения меню на мобильных устройствах */}
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                sx={{ display: {  xs:'block',sm: 'block', md: 'none' } }} // Drawer отображается только на мобильных устройствах
            >
                <List>
                    <ListItem button onClick={() => navigateTo('/dashboard')}>
                        <ListItemText primary={t('panel_expert')} />
                    </ListItem>
                    {user?.roles.includes("admin") && (
                        <>
                            <ListItem button onClick={() => navigateTo('/admin')}>
                                <ListItemText primary={t('statistics')} />
                            </ListItem>
                            <ListItem button onClick={() => navigateTo('/admin/seasons')}>
                                <ListItemText primary={t('manage_seasons')} />
                            </ListItem>
                            <ListItem button onClick={() => navigateTo('/admin/categories')}>
                                <ListItemText primary={t('manage_categories')} />
                            </ListItem>
                            <ListItem button onClick={() => navigateTo('/admin/objects')}>
                                <ListItemText primary={t('manage_objects')} />
                            </ListItem>
                            <ListItem button onClick={() => navigateTo('/admin/experts')}>
                                <ListItemText primary={t('manage_experts')} />
                            </ListItem>
                        </>
                    )}
                </List>
            </Drawer>
        </AppBar>
    );
}
