import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Add, Edit, Delete, CheckCircle, Block } from "@mui/icons-material";
import api from "../api";
import { useNotification } from "../NotificationContext";
import { useLanguage } from "../LanguageContext";
import theme from "../theme";

const rolesOptions = ["admin", "user", "wfs","wfs eng"];
export default function CategoryManager() {
    const [categories, setCategories] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [newCategoryName, setNewCategoryName] = useState("");
    const [newCategoryTitle, setNewCategoryTitle] = useState("");
    const [currentCategory, setCurrentCategory] = useState(null);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isAddMode, setAddMode] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);

    const notify = useNotification();
    const { t } = useLanguage();

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        const filtered = categories.filter((category) =>
            category.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredCategories(filtered);
    }, [searchText, categories]);

    const handleRoleChange = (event) => {
        setSelectedRoles(event.target.value);
    };

    const fetchCategories = async () => {
        try {
            const { data } = await api.get("/api/categories");
            setCategories(data || []);
        } catch (err) {
            notify(t("error_fetching"), "error");
        }
    };

    const handleSaveCategory = async () => {
        if (!newCategoryName.trim()) {
            notify(t("name_required"), "error");
            return;
        }

        try {
            if (isAddMode) {
                const { data } = await api.post("/api/categories", { name: newCategoryName,title:newCategoryTitle,roles: selectedRoles });
                setCategories((prev) => [...prev, data]);
                notify(t("added_successfully"), "success");
            } else {
                await api.put(`/api/categories/${currentCategory.id}`, { name: newCategoryName,title:newCategoryTitle,roles: selectedRoles });
                setCategories((prev) =>
                    prev.map((category) =>
                        category.id === currentCategory.id ? { ...category, ...currentCategory } : category
                    )
                );
                notify(t("updated_successfully"), "success");
            }
            fetchCategories();
            setDialogOpen(false);
            setNewCategoryName("");
            setNewCategoryTitle("");
        } catch (err) {
            notify(t("error_saving"), "error");
        }
    };

    const handleDeleteCategory = async (id) => {
        try {
            await api.delete(`/api/categories/${id}`);
            setCategories((prev) => prev.filter((category) => category.id !== id));
            notify(t("deleted_successfully"), "success");
        } catch (err) {
            notify(t("error_deleting"), "error");
        }
    };

    const handleOpenDialog = (category = null) => {
        if (category) {
            setCurrentCategory(category);
            setSelectedRoles(category.roles)
            setNewCategoryName(category.name);
            setNewCategoryTitle(category.title);
            setAddMode(false);
        } else {
            setCurrentCategory(null);
            setNewCategoryName("");
            setNewCategoryTitle("");
            setAddMode(true);
            setSelectedRoles([]);
        }
        setDialogOpen(true);
    };

    const columns = [
        { field: "title", headerName: t("category_name"), flex: 1 },
        {
            field: "actions",
            headerName: t("actions"),
            sortable: false,
            width: 200,
            renderCell: (params) => (
                <Box>
                    <IconButton
                        color="primary"
                        onClick={() => handleOpenDialog(params.row)}
                    >
                        <Edit />
                    </IconButton>
                    <IconButton
                        color="error"
                        onClick={() => handleDeleteCategory(params.row.id)}
                    >
                        <Delete />
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>
                {t("categories")}
            </Typography>

            <Box display="flex" justifyContent="space-between" mb={2}>
                <TextField
                    label={t("search")}
                    variant="outlined"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    sx={{ flex: 1, mr: 2 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    sx={{ backgroundColor: theme.palette.menu.main,
                        '&:hover': {
                            backgroundColor: theme.palette.menu.inner,  // Пример светлее на 90%
                        }, }}
                    onClick={() => handleOpenDialog()}
                >
                    {t("add")}
                </Button>
            </Box>

            <DataGrid
                rows={filteredCategories}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                slotProps={{
                    pagination: {
                        labelRowsPerPage: t("rows_per_page"),
                        labelDisplayedRows: ({ from, to, count }) => `${from}–${to} ${t("of")} ${count}`, } }}
            />

            <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>
                    {isAddMode ? t("add") : t("edit")}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label={t("category_name")}
                        fullWidth
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("category_title")}
                        fullWidth
                        value={newCategoryTitle}
                        onChange={(e) => setNewCategoryTitle(e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <InputLabel>{t("roles")}</InputLabel>
                        <Select
                            multiple
                            value={selectedRoles}
                            onChange={handleRoleChange}
                            renderValue={(selected) => selected.join(", ")} // Отображение выбранных ролей
                        >
                            {rolesOptions.map((role) => (
                                <MenuItem key={role} value={role}>
                                    <Checkbox checked={selectedRoles.indexOf(role) > -1} />
                                    <ListItemText primary={role} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="secondary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleSaveCategory} color="primary">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
