import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Typography,
    Card,
    CardContent,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Rating, Tabs, Tab, Stack, Badge, CircularProgress
} from "@mui/material";
import api from "../api"
import { useNotification } from "../NotificationContext";
import { useLanguage } from '../LanguageContext';
import ImageGalleryWithRedirects from "./ImageGalleryWithRedirects";
import ResolveImageLinks from "./ImageGalleryWithRedirects";
import theme from "../theme";
import YandexDiskImages from "./YandexDiskImages";
import {LinkWrapper} from "../components/LinkWrapper";

export default function ExpertDashboard(){

    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedObject, setSelectedObject] = useState(null);
    const [voteScore, setVoteScore] = useState(0);
    const [isVoteModalOpen, setVoteModalOpen] = useState(false);
    const notify = useNotification();
    const { t } = useLanguage();

    const [categories, setCategories] = useState([]);
    const [objects, setObjects] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [page, setPage] = useState(1); // Страница по умолчанию
    const [loading, setLoading] = useState(false);

    // Загружаем категории
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const { data } = await api.get("/api/categories/role");
                setCategories(data || []);
                // Устанавливаем первую категорию сразу, если категории загружены
                if (data.length > 0) {
                    setSelectedCategory(data[0].id); // Устанавливаем первую категорию как выбранную
                }
            } catch (err) {
                console.error("Error fetching categories:", err);
            }
        };

        fetchCategories();
    }, []);

    // Загружаем объекты по категории и странице
    const fetchObjects = async (categoryId, reset = false) => {

        if (loading) return; // Не отправляем запрос, если идет загрузка

        setLoading(true);

        // Сбрасываем данные, если категория изменилась
        if (reset) {
            setPage(1); // Сбросить страницу при смене категории
            setObjects([]); // Очистить старые объекты
        }

        try {
            const { data } = await api.get(`/api/opjects-paginations?category_id=${categoryId}&page=${page}&limit=20`);
            setObjects((prevObjects) => [...prevObjects, ...data]); // Добавляем новые объекты
        } catch (err) {
            console.error("Error fetching objects:", err);
        } finally {
            setLoading(false);
        }
    };

    // Обработчик скролла для загрузки данных при достижении конца страницы
    const handleScroll = () => {
        const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
        const documentHeight = document.documentElement.offsetHeight;

        // Когда доходим до конца страницы и не идет загрузка
        if (scrollPosition >= documentHeight - 10 && !loading && selectedCategory !== null) {
            setPage((prevPage) => prevPage + 1); // Увеличиваем номер страницы
        }
    };

    // При изменении страницы или категории загружаем данные
    useEffect(() => {
        if (page > 1 && selectedCategory) {
            fetchObjects(selectedCategory); // Загружаем объекты для следующей страницы
        }
    }, [page, selectedCategory]); // Следим за изменениями page и selectedCategory

    // При изменении категории сбрасываем страницу и очищаем объекты
    useEffect(() => {
        if (selectedCategory) {
            fetchObjects(selectedCategory, true); // Загружаем данные для первой страницы категории
        }
    }, [selectedCategory]); // Следим за изменением выбранной категории

    // Добавляем и удаляем обработчик события скролла
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Убираем обработчик при размонтировании компонента
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loading, selectedCategory]); // Добавляем зависимости для корректной работы

    // Обработчик изменения таба
    const handleTabChange = (event, newValue) => {
        setSelectedCategory(categories[newValue]?.id); // Обновляем выбранную категорию
        setSelectedTab(newValue);
        setPage(1);
    };

    // Improved event handling for scroll and voting
    const handleVoteOpen = (object) => {
        setSelectedObject(object);
        setVoteModalOpen(true);
    };

    // Submit vote for selected object
    const handleVoteSubmit = async () => {
        if (voteScore < 0 || voteScore > 10) {
            notify("Score must be between 0 and 10.", "error");
            return;
        }

        try {
            await api.post("/api/vote", {
                object_id: selectedObject.id,
                category_id: selectedCategory,
                score: voteScore,
            });

            const updatedObjects = objects.map((obj) =>
                obj.id === selectedObject.id
                    ? { ...obj, has_voted: true, score: voteScore, averageScore: calculateNewAverage(obj.averageScore, obj.votesCount, voteScore) }
                    : obj
            );
            setObjects(updatedObjects);

            notify(t('vote_submitted_successfully'), "success");
            setVoteModalOpen(false);
            setVoteScore(0);
            setSelectedObject(null);
        } catch (err) {
            notify("Failed to submit your vote.", "error");
        }
    };

    // Calculate new average score after voting
    const calculateNewAverage = (currentAverage, votesCount, newScore) => {
        return (currentAverage * votesCount + newScore) / (votesCount + 1);
    };

    const getCategoryPath = (categories, selectedCategory) => {
        const category = categories.find((cat) => cat.id === selectedCategory);
        switch (category?.name) {
            case "Показы_МНМ_весна_2025":
                return "/Приложения/Tilda Publishing and Disk/Иллюстрации_брендов_Показы/";
            case "Шоурум_МНМ_весна_2025":
                return "/Приложения/Tilda Publishing and Disk/Шоурум_иллюстрации/";
            case "Маркет_МНМ_весна_2025":
                return "/Приложения/Tilda Publishing and Disk/маркет_иллюстрации/";
            case "WFS_Applications_2025":
                return "/Приложения/Tilda Publishing and Disk/Иллюстрации_брендов_Показы/";
            case "WFS_Applications_2025_eng":
                return "/Приложения/Tilda Publishing and Disk/Иллюстрации_брендов_Показы/";
            default:
                return "";
        }
    };
    const pathcat = getCategoryPath(categories, selectedCategory);

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                {t('expert_dashboard')}
            </Typography>

            <Typography variant="h6" gutterBottom>
                {t('categories')}
            </Typography>
            <Box>
                {/* Tabs Navigation */}
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {categories.map((category, index) => (
                        <Tab label={category.title} key={category.id} sx={{ cursor: 'pointer' }} />
                    ))}
                </Tabs>

                {/* Tabs Content */}
                {categories.map((category, index) => (
                    <Box
                        key={category.id}
                        role="tabpanel"
                        hidden={selectedTab !== index}
                        sx={{ mt: 2 }}
                    >
                        {selectedCategory && (
                            <>
                                <Typography variant="h6" sx={{ mt: 4 }}>
                                    {t('objects_in')} {categories.find((category) => category.id === selectedCategory)?.title}
                                </Typography>
                                <Stack spacing={1} sx={{ mt: 2 }}>
                                    {objects.map((obj) => (
                                        <Card key={obj.id} onClick={() => handleVoteOpen(obj)} sx={{ cursor: 'pointer' }}>
                                            <CardContent>
                                                <Box display="flex" alignItems="center" gap={2}>
                                                    {obj.score !== "" && (
                                                        <Box
                                                            sx={{
                                                                width: 40,
                                                                height: 40,
                                                                borderRadius: '50%',
                                                                backgroundColor: 'black',
                                                                color: 'white',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                fontSize: '1.2rem',
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            {obj.score}
                                                        </Box>
                                                    )}

                                                    <Box>
                                                        <Typography variant="h6">
                                                            {obj.brand_name ? obj.brand_name : obj.company_name}
                                                        </Typography>
                                                        <Typography variant="body2">{obj.description}</Typography>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>

                                    ))}
                                    {loading && (
                                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="20vh">
                                            <CircularProgress style={{ color: "black" }} />
                                            <Typography variant="h6" style={{ marginTop: 16 }}>
                                                Загрузка...
                                            </Typography>
                                        </Box>
                                    )}
                                </Stack>
                            </>
                        )}
                    </Box>
                ))}
            </Box>


            {/* Vote Modal */}
            <Dialog open={isVoteModalOpen} onClose={() => setVoteModalOpen(false)}>
                <DialogTitle>{t('vote_for')} {selectedObject?.brand_name || selectedObject?.company_name}</DialogTitle>
                <DialogContent>
                    {/* Основные данные */}
                    <YandexDiskImages filePaths={selectedObject?.illustrations} pathcat={pathcat} />
                    <Typography variant="body2" sx={{ mb: 1 }}>
                        <b>{t('jur_lico')}:</b> {selectedObject?.full_company_legal_name || selectedObject?.company_name}
                    </Typography>
                    {
                        (selectedObject?.brand_name || selectedObject?.film_title) ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('object_name')}:</b> {selectedObject?.brand_name || selectedObject?.film_title}
                            </Typography>
                        ):null
                    }

                    {
                        selectedObject?.inn ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('INN')}:</b> {selectedObject?.inn}
                            </Typography>
                        ): null
                    }

                    {
                        selectedObject?.registration_address ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('adress_registration')}:</b> {selectedObject?.registration_address}
                            </Typography>
                        ): null
                    }

                    {
                        selectedObject?.year_of_foundation ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('year_of_foundation')}:</b> {selectedObject?.year_of_foundation || t('unknown')}
                            </Typography>
                        ): null
                    }

                    {
                        selectedObject?.city ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('city')}:</b> {selectedObject?.city}
                            </Typography>
                        ): null
                    }

                    {
                        selectedObject?.collection ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('collection')}:</b> {selectedObject?.collection} ({selectedObject?.collection_category})
                            </Typography>
                        ): null
                    }

                    {
                        selectedObject?.info_about_brand ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('info_about_brand')}:</b> {selectedObject?.info_about_brand}
                            </Typography>
                        ): null
                    }

                    {
                        selectedObject?.website ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('website')}:</b> <a href={selectedObject?.website} target="_blank" rel="noopener noreferrer">{selectedObject?.website}</a>
                            </Typography>
                        ):null
                    }

                    {
                        selectedObject?.socials ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('socials')}:</b> {selectedObject?.socials}
                            </Typography>
                        ):null
                    }

                    {
                        selectedObject?.logo ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('logo')}:</b> <a href={selectedObject?.logo} target="_blank" rel="noopener noreferrer">{selectedObject?.logo}</a>
                            </Typography>
                        ):null
                    }

                    {
                        selectedObject?.creators_of_the_film ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('creators_of_the_film')}:</b> {selectedObject?.creators_of_the_film}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.director ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('director')}:</b> {selectedObject?.director}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.producer ? (<Typography variant="body2" sx={{ mb: 1 }}>
                            <b>{t('producer')}:</b> {selectedObject?.producer}
                        </Typography>):null
                    }
                    {
                        selectedObject?.composer ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('composer')}:</b> {selectedObject?.composer}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.other_creators ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('other_creators')}:</b> {selectedObject?.other_creators}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.country_of_production ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('country_of_production')}:</b> {selectedObject?.country_of_production}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.film_length ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('film_length')}:</b> {selectedObject?.film_length}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.language ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('language')}:</b> {selectedObject?.language}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.format ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('format')}:</b> {selectedObject?.format}
                            </Typography>
                        ):null
                    }

                    {
                        selectedObject?.rightsholder ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('rightsholder')}:</b> {selectedObject?.rightsholder}
                            </Typography>
                        ):null
                    }

                    {
                        selectedObject?.did_the_film_participate_in_festivals ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('did_the_film_participate_in_festivals')}:</b> {selectedObject?.did_the_film_participate_in_festivals}
                            </Typography>
                        ):null
                    }

                    {
                        selectedObject?.short_bios_of_important_creators ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('short_bios_of_important_creators')}:</b> {selectedObject?.short_bios_of_important_creators}
                            </Typography>
                        ):null
                    }

                    {
                        selectedObject?.film_synopsis ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('film_synopsis')}:</b> {selectedObject?.film_synopsis}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.film_link ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('film_link')}:</b> {selectedObject?.film_link}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.contact_person ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('contact_person')}:</b> {selectedObject?.contact_person}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.phone ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('phone')}:</b> {selectedObject?.phone}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.email ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('email')}:</b> {selectedObject?.email}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.upload_signed_agreement ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('upload_signed_agreement')}:</b> {selectedObject?.upload_signed_agreement}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.brand_direction ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('brand_direction')}:</b> {selectedObject?.brand_direction}
                            </Typography>
                        ):null
                    }

                    {
                        selectedObject?.selling_points ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('selling_points')}:</b>
                                <LinkWrapper text={selectedObject?.selling_points} />
                            </Typography>
                        ):null
                    }

                    {
                        selectedObject?.segment ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('segment')}:</b> {selectedObject?.segment}
                            </Typography>
                        ):null

                    }
                    {
                        selectedObject?.company_participation_info ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('company_participation_info')}:</b> {selectedObject?.company_participation_info}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.lookbook ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('lookbook')}:</b> <a href={selectedObject?.lookbook} target="_blank" rel="noopener noreferrer">{selectedObject?.lookbook}</a>
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.designer ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('designer')}:</b> {selectedObject?.designer}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.moodboard ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('moodboard')}:</b><a href={selectedObject?.moodboard} target="_blank" rel="noopener noreferrer">{selectedObject?.moodboard}</a>
                            </Typography>
                        ):null
                    }

                    {
                        selectedObject?.linesheet ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('linesheet')}:</b> <a href={selectedObject?.linesheet} target="_blank" rel="noopener noreferrer">{selectedObject?.linesheet}</a>
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.price_range ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('price_range')}:</b> {selectedObject?.price_range}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.production ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('production')}:</b> {selectedObject?.production}
                            </Typography>
                        ):null
                    }
                    {
                        selectedObject?.previous_showcase ? (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <b>{t('previous_showcase')}:</b>
                                <LinkWrapper text={selectedObject?.previous_showcase} />
                            </Typography>
                        ):null
                    }

                    {selectedObject?.archives?.length > 0 && (
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            <b>{t('archives')}:</b>
                            <ul>
                                {selectedObject.archives.map((archive, index) => (
                                    <li key={index}>
                                        <a href={archive} target="_blank" rel="noopener noreferrer">{archive}</a>
                                    </li>
                                ))}
                            </ul>
                        </Typography>
                    )}

                    {selectedObject?.media_coverages?.length > 0 && (
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            <b>{t('media_coverages')}:</b>
                            <ul>
                                {selectedObject.media_coverages.map((media, index) => (
                                    <li key={index}>
                                        <a href={media} target="_blank" rel="noopener noreferrer">{media}</a>
                                    </li>
                                ))}
                            </ul>
                        </Typography>
                    )}
                    {/*<ResolveImageLinks links={} />*/}


                    {/*{selectedObject?.illustrations?.length > 0 && ()}*/}
                    {/*{selectedObject?.illustrations?.length > 0 && (*/}
                    {/*    <Typography variant="body2" sx={{ mb: 1 }}>*/}
                    {/*        <b>{t('illustrations')}:</b>*/}
                    {/*        <ul>*/}
                    {/*            {selectedObject.illustrations.map((illustration, index) => (*/}
                    {/*                <li key={index}>*/}
                    {/*                    <a href={illustration} target="_blank" rel="noopener noreferrer">{illustration}</a>*/}
                    {/*                </li>*/}
                    {/*            ))}*/}
                    {/*        </ul>*/}
                    {/*    </Typography>*/}
                    {/*)}*/}

                    {/* Политики и права */}
                    {/*{*/}
                    {/*    selectedObject?.confidentiality_policy ? (*/}
                    {/*        <Typography variant="body2" sx={{ mb: 1 }}>*/}
                    {/*            <b>{t('confidentiality_policy')}:</b> {selectedObject?.confidentiality_policy ? t('yes') : t('no')}*/}
                    {/*        </Typography>*/}
                    {/*    ):null*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    selectedObject?.contest_conditions ? (*/}
                    {/*        <Typography variant="body2" sx={{ mb: 1 }}>*/}
                    {/*            <b>{t('contest_conditions')}:</b> {selectedObject?.contest_conditions ? t('yes') : t('no')}*/}
                    {/*        </Typography>*/}
                    {/*    ):null*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    selectedObject?.unique_collection ? (*/}
                    {/*        <Typography variant="body2" sx={{ mb: 1 }}>*/}
                    {/*            <b>{t('unique_collection')}:</b> {selectedObject?.unique_collection ? t('yes') : t('no')}*/}
                    {/*        </Typography>*/}
                    {/*    ):null*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    selectedObject?.data_processing_consent ? (*/}
                    {/*        <Typography variant="body2" sx={{ mb: 1 }}>*/}
                    {/*            <b>{t('obrabotka_dannykh')}:</b> {selectedObject?.data_processing_consent ? t('yes') : t('no')}*/}
                    {/*        </Typography>*/}
                    {/*    ):null*/}
                    {/*}*/}

                    {/*{*/}
                    {/*    selectedObject?.third_party_rights ? (*/}
                    {/*        <Typography variant="body2" sx={{ mb: 1 }}>*/}
                    {/*            <b>{t('third_party_rights')}:</b> {selectedObject?.third_party_rights ? t('yes') : t('no')}*/}
                    {/*        </Typography>*/}
                    {/*    ):null*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    selectedObject?.is_not_foreign_entity ? (*/}
                    {/*        <Typography variant="body2" sx={{ mb: 1 }}>*/}
                    {/*            <b>{t('is_not_foreign_entity')}:</b> {selectedObject?.is_not_foreign_entity ? t('yes') : t('no')}*/}
                    {/*        </Typography>*/}
                    {/*    ):null*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    selectedObject?.is_not_government_funded_entity ? (*/}
                    {/*        <Typography variant="body2" sx={{ mb: 1 }}>*/}
                    {/*            <b>{t('is_not_government_funded_entity')}:</b> {selectedObject?.is_not_government_funded_entity ? t('yes') : t('no')}*/}
                    {/*        </Typography>*/}
                    {/*    ):null*/}
                    {/*}*/}

                    {!selectedObject?.has_voted && (
                        <Box>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            <b>{t('please_provide_your_score_for_the_object')}:</b>
                        </Typography>

                        <Box>
                        <Rating
                        name="object-rating"
                        value={voteScore}
                    precision={1}
                    max={10}
                    onChange={(event, newValue) => setVoteScore(newValue)}
                />
                <p>{t('your_score')}: {voteScore}</p>
        </Box>
                        </Box>
                    ) }

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setVoteModalOpen(false)} color="secondary">
                        {t('cancel')}
                    </Button>
                    {!selectedObject?.has_voted && (
                    <Button onClick={handleVoteSubmit} color="primary">
                        {t('submit')}
                    </Button>
                        )}
                </DialogActions>
            </Dialog>



        </Box>
    );
}