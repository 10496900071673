import React from 'react';
import { useLanguage } from './LanguageContext';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const LanguageSelector = () => {
    const { language, changeLanguage } = useLanguage();

    const handleLanguageChange = (event) => {
        changeLanguage(event.target.value); // Изменяем язык на выбранный
    };

    return (
        <div>
            <FormControl fullWidth sx={{ minWidth: 50 }}>
                {/* Убираем InputLabel, чтобы не отображалась надпись */}
                <Select
                    value={language}  // Текущий выбранный язык
                    onChange={handleLanguageChange}  // Обработчик изменения языка
                    sx={{
                        color: 'white', // Цвет текста белый
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none', // Убираем рамку
                        },
                        '& .MuiSelect-icon': {
                            color: 'white', // Делаем иконку выбора белой
                        },
                    }}
                >
                    <MenuItem value="ru">Rus</MenuItem>
                    <MenuItem value="en">Eng</MenuItem>
                    {/* Добавьте другие языки по мере необходимости */}
                </Select>
            </FormControl>
        </div>
    );
};

export default LanguageSelector;
