import React, { useState } from "react";
import { useNavigate, Link  } from "react-router-dom";
import api from "../api";
import { useNotification } from "../NotificationContext";
import { useAuth } from "../AuthContext";
import {Box, TextField, Button, Typography } from "@mui/material";
import { useLanguage } from '../LanguageContext';
import theme from "../theme";

export default function Login(){
    const [email,setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const notify = useNotification();
    const { login } = useAuth();
    const { t } = useLanguage();

    const handleLogin = async  () => {
        try{
            const { data } = await api.post("/auth/login",{ email, password });
            localStorage.setItem("access_token", data.access_token);
            localStorage.setItem("refresh_token", data.refresh_token);

            const userData = { email: data.email,roles: data.roles }
            login(userData)

            notify(t('login_successful'), "success");
            navigate("/dashboard");
        } catch (err){
            notify("Login failed. Please check your credentials.", "error");
        }
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>{t('login')}</Typography>
            <TextField
                label="Email"
                type="email"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                label={t('password')}
                type="password"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={handleLogin} sx={{ backgroundColor: theme.palette.menu.main,
                '&:hover': {
                    backgroundColor: theme.palette.menu.inner,  // Пример светлее на 90%
                }, }} >
                {t('login')}
            </Button>
            <Typography variant="body2" sx={{ marginTop: 2 }}>
                <Link to="/reset-password" style={{ cursor: "pointer" }}>
                    {t('forgot_password')}
                </Link>
            </Typography>
        </Box>
    )
}