import React, { createContext, useContext, useState, useEffect } from 'react';

// Переводы для разных языков
const translations = {
    en: {
        add:"Add",
        admin:"Admin",
        current:"Current",
        edit:"Edit",
        set_current:"Set current",
        panel_expert:"Dashboard",
        login: "Login",
        logout:"Logout",
        login_successful:"Login successful !",
        password: "Password",
        expert_dashboard:"Expert Dashboard",
        view_objects:"View Objects",
        categories:"Categories",
        objects_in:"Objects in",
        vote:"vote",
        already_voted:"Already Voted",
        vote_for:"Vote for",
        your_score:"Your score",
        cancel:"Cancel",
        submit:"Submit",
        please_provide_your_score_for_the_object:"Please provide your score for the object",
        welcome: "Welcome to our site",
        vote_submitted_successfully:"Vote submitted successfully!",
        object:"Object",
        total_votes:"Total Votes",
        average_score:"Average Score",
        admin_dashboard:"Admin Dashboard",
        statistics:"Statistics",
        manage_seasons:"Seasons",
        manage_experts:"Experts",
        manage_objects:"Objects",
        error_fetching_seasons:"Error fetching seasons",
        season_name_required:"Season name required",
        season_added_successfully:"Season added successfully",
        error_adding_season:"Error adding season",
        season_updated_successfully:"Season updated successfully",
        error_updating_season:"Error updating season",
        season_deleted_successfully:"Season deleted successfully",
        error_deleting_season:"Error deleting season",
        season_set_as_current:"Season set as current",
        error_setting_current_season:"Error setting current season",
        seasons:"Seasons",
        save:"Save",
        new_season:"New season",
        season_name:"Season name",
        search:"Search",
        actions:"Actions",
        users:"Users",
        username:"Username",
        email:"Email",
        voting_objects:"Voting objects",
        object_name:"Object name",
        jur_lico:"Legal entity",
        INN:"TIN",
        adress_registration:"Registration address",
        year_of_foundation:"Year of foundation",
        city:"City",
        collection:"Collection",
        confidentiality_policy:"Confidentiality policy",
        sent:"Sent",
        media_coverages:"Media coverages",
        archives:"Archives",
        illustrations:"Illustrations",
        enter_comma_separated:"Enter comma separated",
        media_coverage_of_brand:"Media coverage of brand",
        info_about_brand:"Info about brand",
        previous_experience:"Previous experience",
        socials:"Socials",
        website:"Website",
        logo:"Logo",
        moodboard:"Moodboard",
        selling_points:"Selling points",
        designer:"Designer",
        contact_person:"Contact person",
        contest_conditions:"Contest conditions",
        unique_collection:"Unique collection",
        obrabotka_dannykh:"Permission to process data",
        third_party_rights:"Third party rights",
        is_not_foreign_entity:"Is not foreign entity",
        is_not_government_funded_entity:"Is not government funded entity",
        category:"Categories",
        object_added_successfully:"Object added successfully",
        confirm_registration:"Set a password",
        confirm_password:"Confirm password",
        confirmation_failed:"Сonfirmation failed",
        registration_confirmed:"Registration confirmed",
        password_length_error:"password_length_error",
        password_mismatch:"password_mismatch",
        manage_categories:"Сategories",
        category_name:"Name",
        load:"Load",
        roles:"Roles",
        category_title:"Category title",
        phone:"Phone",
        previous_showcase:"Previous showcase",
        creators_of_the_film:"Creators of the film",
        director:"Director",
        producer:"Producer",
        composer:"Composer",
        other_creators:"Other_creators",
        country_of_production:"Country of production",
        language:"Language",
        format:"Format",
        rightsholder:"Rightsholder",
        did_the_film_participate_in_festivals:"Did the film participate in festivals",
        short_bios_of_important_creators:"Short bios of important creators",
        film_synopsis:"Film synopsis",
        film_link:"Film link",
        upload_signed_agreement:"Upload signed agreement",
        role:"Role",
        brand_direction:"Brand_direction",
        segment:"Segment",
        company_participation_info:"Company participation info",
        lookbook:"Lookbook",
        linesheet:"Linesheet",
        price_range:"Price range",
        production:"Production",
        company_name:"Company name",
        collection_category: "Collection category",
        film_title:"Film title",
        film_length: "Film_length",
        forgot_password:"Forgot password",
        reset_password:"Reset password",
        enter_email_reset:"Enter email",
        send_reset_link:"Send reset link",
        password_reset_email_sent:"Password reset email sent",
        logoI:"/logo/logo_eng.png",
        image:"Image",
        of:"of",
        export_excel:"Export to Excel",
        rows_per_page:"Rows per page",
    },
    ru: {
        new_season:"Новый сезон",
        add:"Добавить",
        admin:"Администратор",
        current:"Текущий",
        edit:"Редактировать",
        set_current:"Установить текущим",
        panel_expert:"Экспертная панель",
        login: "Войти",
        logout:"Выйти",
        login_successful:"Вы успешно вошли !",
        password: "Пароль",
        expert_dashboard:"Экспертная панель",
        view_objects:"Просмотр заявок",
        categories:"Категории",
        objects_in:"Заявки в",
        vote:"Проголосовать",
        already_voted:"Ваш голос принят",
        vote_for:"Голосовать за",
        please_provide_your_score_for_the_object:"Пожалуйста, укажите вашу оценку",
        your_score:"Ваша оценка",
        cancel:"Отменить",
        submit:"Отправить",
        welcome: "Добро пожаловать на наш сайт",
        vote_submitted_successfully:"Ваш голос успешно учтен!",
        object:"Заявка",
        total_votes:"Всего голосов",
        average_score:"Срдняя оценка",
        admin_dashboard:"Панель администратора",
        statistics:"Статистика",
        manage_seasons:"Сезоны",
        manage_experts:"Эксперты",
        manage_objects:"Заявки голосования",
        error_fetching_seasons:"Ошибка получения сезонов",
        season_name_required:"Название сезона обязательно",
        season_added_successfully:"Сезон добавлен успешно",
        error_adding_season:"Ошибка добавления сезона",
        season_updated_successfully:"Сезон успешно обновлен",
        error_updating_season:"Ошибка обновления сезона",
        season_deleted_successfully:"Сезон успешно удален",
        error_deleting:"Ошибка удаления",
        season_set_as_current:"Сезон текущим",
        error_setting_current_season:"Ошибка",
        seasons:"Сезоны",
        save:"Сохранить",
        season_name:"Наименование сезона",
        search:"Поиск",
        actions:"Действия",
        users:"Пользователи",
        username:"Пользователь",
        email:"Email",
        voting_objects:"Заявки для голосования",
        object_name:"Наименование заявки",
        jur_lico:"Юр. лицо",
        INN:"ИНН",
        adress_registration:"Адрес регистрации",
        year_of_foundation:"Год основания",
        city:"Город",
        collection:"Коллекция",
        confidentiality_policy:"Политика конфиденциальности",
        sent:"Отправленно",
        media_coverages:"Освещение в СМИ",
        archives:"Архивы",
        illustrations:"Иллюстрации",
        enter_comma_separated:"Ввод через запятую",
        media_coverage_of_brand:"СМИ о бренде",
        info_about_brand:"Информация о бренде",
        previous_experience:"Предыдущий опыт",
        socials:"Соц. сети",
        website:"Сайт",
        logo:"Логотип",
        moodboard:"Мудборд",
        selling_points:"Точки продаж",
        designer:"Дизайнер",
        contact_person:"Контактное лицо",
        contest_conditions:"Условия конкурс",
        unique_collection:"Уникальная коллекция",
        obrabotka_dannykh:"Разрешение на обработку данных",
        third_party_rights:"Права третьих лиц",
        is_not_foreign_entity:"Не является ин. агентом",
        is_not_government_funded_entity:"Не финансируется государством",
        category:"Категории",
        object_added_successfully:"Заявка успешно добавлена",
        confirm_registration:"Задайте пароль",
        confirm_password:"Повторите пароль",
        confirmation_failed:"Ошибка подтверждения",
        registration_confirmed:"Регистрация подтверждена",
        password_length_error:"Длина пароля меньше 6 символов",
        password_mismatch:"Введенные пароли не совпадают",
        manage_categories:"Категории",
        category_name:"Наименование",
        load:"Загрузить",
        roles:"Роли",
        category_title:"Выводимое имя",
        phone:"Телефон",
        previous_showcase:"Предыдущие показы",
        creators_of_the_film:"Создатели фильма",
        director:"Режисер",
        producer:"Продюсер",
        composer:"Компоситор",
        other_creators:"Другие создатели",
        country_of_production:"Страна производства",
        language:"Язык",
        format:"Формат",
        rightsholder:"Правообладатель",
        did_the_film_participate_in_festivals:"Участвовал ли фильм в фестивалях",
        short_bios_of_important_creators:"Краткие биографии важных создателей",
        film_synopsis:"Синопсис",
        film_link:"Ссылка на фильм",
        upload_signed_agreement:"Подписанное соглашщение",
        role:"Роль",
        brand_direction:"Направление бренда",
        segment:"Сегмент",
        company_participation_info:"Информация об участии компании",
        lookbook:"Лукбук",
        linesheet:"Linesheet",
        price_range:"Диапазон цен",
        production:"Производство",
        company_name:"Наименование компании",
        collection_category: "Категория коллекции",
        film_title:"Название фильма",
        film_length: "Продолжительность",
        forgot_password:"Забыли пароль",
        reset_password:"Восстановить пароль",
        enter_email_reset:"Введите email",
        send_reset_link:"Отправить ссылку",
        password_reset_email_sent:"Письмо для восстановления пароля успешно отправленно",
        logoI:"/logo/logo.png",
        image:"Изображение",
        of:"из",
        export_excel:"Экспорт в Excel",
        rows_per_page:"Строк на странице",

    },
    // Добавьте другие языки по мере необходимости
};

// Создание контекста для языка
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState("en");

    // Загрузка языка из localStorage, если он был сохранен
    useEffect(() => {
        const savedLanguage = localStorage.getItem("language");
        if (savedLanguage) {
            setLanguage(savedLanguage);
        } else {
            // Если языка в localStorage нет, определяем язык пользователя
            const userLanguage = navigator.language || navigator.languages;
            const lang = userLanguage.startsWith("ru") ? "ru" : "en"; // Если язык начинается с "ru", выбираем русский
            setLanguage(lang);
        }
    }, []);

    // Функция для изменения языка
    const changeLanguage = (newLang) => {
        setLanguage(newLang);
        localStorage.setItem("language", newLang); // Сохраняем язык в localStorage
    };

    // Функция для получения перевода
    const t = (key) => {
        return translations[language][key] || key; // Если перевода нет, возвращаем ключ
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage, t }}>
            {children}
        </LanguageContext.Provider>
    );
};

// Хук для использования контекста
export const useLanguage = () => {
    return useContext(LanguageContext);
};