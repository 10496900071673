import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use((config) => {
   const token = localStorage.getItem("access_token");
   if (token) {
       config.headers.Authorization = `Bearer ${token}`;
   }
   return config;
});


// Обработка истечения токена
api.interceptors.response.use(
    (response) => response, // Если запрос успешен, просто вернуть ответ
    async (error) => {
        const originalRequest = error.config;

        // Проверка статуса ошибки и предотвращение зацикливания
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // Пометка, чтобы не пытаться снова на одном запросе

            try {
                const refreshToken = localStorage.getItem("refresh_token");

                if (refreshToken) {
                    // Отправка запроса на обновление токенов
                    const { data } = await axios.post(
                        `${process.env.REACT_APP_API_BASE_URL}/auth/refresh`,
                        { refresh_token: refreshToken }
                    );

                    // Сохранение нового токена
                    localStorage.setItem("access_token", data.access_token);
                    if (data.refresh_token) {
                        localStorage.setItem("refresh_token", data.refresh_token);
                    }

                    // Обновление заголовков и повторный запрос
                    api.defaults.headers.Authorization = `Bearer ${data.access_token}`;
                    originalRequest.headers.Authorization = `Bearer ${data.access_token}`;

                    return api(originalRequest); // Повторить запрос с новым токеном
                } else {
                    throw new Error("Refresh token not available.");
                }
            } catch (refreshError) {
                console.error("Failed to refresh token:", refreshError);

                // Очистка токенов и редирект на логин
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                localStorage.removeItem("user");
                window.location.href = "/login"; // Перенаправление пользователя
            }
        }

        return Promise.reject(error); // Если ошибка не связана с 401 или рефреш провалился, отклонить
    }
);


export default api;