import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { useNotification } from "../NotificationContext";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useLanguage } from '../LanguageContext';
import theme from "../theme";

export default function ResetPassword() {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const notify = useNotification();
    const { t } = useLanguage();

    const handleResetPassword = async () => {
        try {
            await api.post("/api/reset", { email });
            notify(t('password_reset_email_sent'), "success");
            navigate("/login");
        } catch (err) {
            notify(t('password_reset_failed'), "error");
        }
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>{t('reset_password')}</Typography>
            <Typography variant="body1" gutterBottom>
                {t('enter_email_reset')}
            </Typography>
            <TextField
                label="Email"
                type="email"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <Button variant="contained" color="primary" sx={{ backgroundColor: theme.palette.menu.main,
                '&:hover': {
                    backgroundColor: theme.palette.menu.inner,  // Пример светлее на 90%
                }, }} onClick={handleResetPassword}>
                {t('send_reset_link')}
            </Button>
        </Box>
    );
}
