import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import theme from "./theme";
import {ThemeProvider} from "@mui/material";
import { NotificationProvider } from "./NotificationContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
          <NotificationProvider>
            <App />
          </NotificationProvider>
      </ThemeProvider>
  </React.StrictMode>
);
