import {useLanguage} from "../../LanguageContext";
import {Box, Pagination} from "@mui/material";
import React from "react";

export const CustomPagination = ({ page, pageSize, totalRows, onPageChange }) => {
    const totalPages = Math.ceil(totalRows / pageSize);
    const { t } = useLanguage();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
                {`${t("rows_per_page")} ${page * pageSize + 1}–${Math.min((page + 1) * pageSize, totalRows)} ${t("of")} ${totalRows}`}
            </div>
            <Pagination
                count={totalPages}
                page={page + 1}
                onChange={(event, value) => onPageChange(value - 1)}
                size="small"
            />
        </Box>
    );
};