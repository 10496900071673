import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper, AccordionDetails, AccordionSummary, Accordion, Button,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import api from "../api";
import { useLanguage } from '../LanguageContext';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function AdminDashboard() {
    const [categories, setCategories] = useState([]);
    const { t } = useLanguage();

    useEffect(() => {
        fetchDashboardData();
    }, []);

    const fetchDashboardData = async () => {
        const { data } = await api.get("/api/dashboard");
        setCategories(data); // Сохраняем весь массив категорий
    };

    const exportToExcel = (data, filename) => {
        // Преобразуем данные в формат, подходящий для Excel
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Задание ширины столбцов
        const wscols = [
            { wch: 20 },
            { wch: 30 },
            { wch: 15 },
            { wch: 15 },
        ];

        worksheet['!cols'] = wscols; // Применяем ширину столбцов

        // Добавляем фильтр для всей области данных
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        worksheet['!autofilter'] = { ref: XLSX.utils.encode_range(range) };

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

        // Создаем Excel-файл
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        // Сохраняем файл
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, `${filename}.xlsx`);
    };



    const handleExport = () => {
        const currentDate = new Date().toLocaleString();

        // Собираем список всех уникальных голосующих
        const allVoters = Array.from(
            new Set(
                categories.flatMap((category) =>
                    category.objects.flatMap((object) =>
                        object.votes.map((vote) => vote.voter_id)
                    )
                )
            )
        );

        // Преобразуем данные в формат для экспорта
        const dataToExport = categories.flatMap((category) =>
            category.objects.map((object) => {
                const row = {
                    Категория: category.category_name,
                    Заявка: object.object_name,
                    Проголосовало: object.total_votes,
                    Балл: object.average_score.toFixed(2),
                };

                // Добавляем баллы каждого голосующего в соответствующий столбец
                allVoters.forEach((voter) => {
                    const vote = object.votes.find((v) => v.voter_id === voter);
                    row[voter] = vote ? vote.score : ""; // Если голосующего нет, оставляем ячейку пустой
                });

                return row;
            })
        );

        // Экспортируем данные в Excel
        exportToExcel(dataToExport, "Данные_голосования_" + currentDate);
    };


    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                {t('admin_dashboard')}
            </Typography>
            <Box sx={{ textAlign: 'right', marginBottom: 2 }}>
                <Button variant="contained" color="primary" onClick={handleExport}>
                    {t('export_excel')}
                </Button>
            </Box>

            {categories.map((category) => (
                <Accordion key={category.category_id} sx={{ marginTop: 2 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${category.category_id}-content`}
                        id={`panel-${category.category_id}-header`}
                    >
                        <Typography variant="h5" gutterBottom>
                            {category.category_name}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paper sx={{ mt: 2 }}>
                            <Box sx={{ overflowX: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('object')}</TableCell>
                                        <TableCell>{t('total_votes')}</TableCell>
                                        <TableCell>{t('average_score')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {category.objects.map((object) => (
                                        <TableRow key={object.object_id}>
                                            <TableCell>{object.object_name}</TableCell>
                                            <TableCell>{object.total_votes}</TableCell>
                                            <TableCell>{object.average_score.toFixed(2)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            </Box>
                        </Paper>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}
