export const LinkWrapper = ({ text }) => {
    // Регулярное выражение для поиска URL
    const urlRegex = /(https?:\/\/\S+)/g;

    const parts = text.split(urlRegex);

    return (
        <p>
            {parts.map((part, index) => {
                // Если часть совпадает с URL, оборачиваем её в <a>
                if (urlRegex.test(part)) {
                    return (
                        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                            {part}
                        </a>
                    );
                }
                // Иначе просто возвращаем текст
                return part;
            })}
        </p>
    );
};