import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton, FormControl, InputLabel, Select, MenuItem, Pagination,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {Add, Edit, Delete, CheckCircle, Block} from "@mui/icons-material";
import api from "../api";
import { useNotification } from "../NotificationContext";
import { useLanguage } from "../LanguageContext";
import theme from "../theme";
import {CustomGrid} from "../components/Grid/CustomGrid";

const rolesOptions = ["admin", "user", "wfs","wfs eng"];

export default function ExpertManager() {
    const [users, setUsers] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [searchText, setSearchText] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isAddMode, setAddMode] = useState(true);
    const [currentUser, setCurrentUser] = useState({ username: "", email: "" });

    const notify = useNotification();
    const { t } = useLanguage();

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        const filtered = users.filter(
            (user) =>
                user.username.toLowerCase().includes(searchText.toLowerCase()) ||
                user.email.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredUsers(filtered);
    }, [searchText, users]);

    const fetchUsers = async () => {
        try {
            const { data } = await api.get("/api/users");
            setUsers(data || []);
        } catch (err) {
            notify(t("error_fetching"), "error");
        }
    };

    const handleSaveUser = async () => {
        if (!currentUser.username.trim() || !currentUser.email.trim()) {
            notify(t("fields_required"), "error");
            return;
        }

        try {
            if (isAddMode) {
                const { data } = await api.post("/api/users", currentUser);
               // setUsers((prev) => [...prev, data]);
                notify(t("added_successfully"), "success");
            } else {
                await api.put(`/api/users/${currentUser.id}`, currentUser);
                setUsers((prev) =>
                    prev.map((user) =>
                        user.id === currentUser.id ? { ...user, ...currentUser } : user
                    )
                );
                notify(t("user_updated_successfully"), "success");
            }
            fetchUsers()
            setDialogOpen(false);
            setCurrentUser({ username: "", email: "" });
        } catch (err) {
            notify(t("error_saving"), "error");
        }
    };

    const handleDeleteUser = async (id) => {
        try {
            await api.delete(`/api/users/${id}`);
            setUsers((prev) => prev.filter((user) => user.id !== id));
            notify(t("deleted_successfully"), "success");
        } catch (err) {
            notify(t("error_deleting"), "error");
        }
    };

    const handleSwitchUser = async (id) => {
        try {
            const { data } = await api.put(`/api/users/switch/${id}`);
            setUsers((prev) =>
                prev.map((user) =>
                    user.id === id ? { ...user, is_active: !user.is_active } : user
                )
            );
            notify(
                // data.isActive
                 //   ? t("activated_successfully")
                 //   : t("blocked_successfully"),
                "success"
            );
        } catch (err) {
            notify(t("error_switching_user"), "error");
        }
    };

    const handleOpenDialog = (user = null) => {
        if (user) {
            setCurrentUser(user);
            setAddMode(false);
        } else {
            setCurrentUser({ username: "", email: "" });
            setAddMode(true);
        }
        setDialogOpen(true);
    };

    const columns = [
        { field: "username", headerName: t("username"), flex: 1 },
        { field: "email", headerName: t("email"), flex: 1 },
        {
            field: "actions",
            headerName: t("actions"),
            sortable: false,
            width: 200,
            renderCell: (params) => (
                <Box>
                    <IconButton
                        color="primary"
                        onClick={() => handleOpenDialog(params.row)}
                    >
                        <Edit />
                    </IconButton>
                    <IconButton
                        color={params.row.is_active ? "success" : "warning"}
                        onClick={() => handleSwitchUser(params.row.id)}
                    >
                        {params.row.is_active ? <CheckCircle /> : <Block />}
                    </IconButton>
                    <IconButton
                        color="error"
                        onClick={() => handleDeleteUser(params.row.id)}
                    >
                        <Delete />
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>
                {t("users")}
            </Typography>

            <Box display="flex" justifyContent="space-between" mb={2}>
                <TextField
                    label={t("search")}
                    variant="outlined"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    sx={{ flex: 1, mr: 2 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => handleOpenDialog()}
                    sx={{ backgroundColor: theme.palette.menu.main,
                        '&:hover': {
                            backgroundColor: theme.palette.menu.inner,  // Пример светлее на 90%
                        }, }}
                >
                    {t("add")}
                </Button>
            </Box>

            {/*<CustomGrid*/}
            {/*    data={filteredUsers}*/}
            {/*    columns={columns}*/}
            {/*/>*/}

            <DataGrid
                rows={filteredUsers}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                slotProps={{
                    pagination: {
                    labelRowsPerPage: t("rows_per_page"),
                        labelDisplayedRows: ({ from, to, count }) => `${from}–${to} ${t("of")} ${count}`, } }}
            />

            <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>
                    {isAddMode ? t("add") : t("edit")}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label={t("username")}
                        fullWidth
                        value={currentUser.username}
                        onChange={(e) =>
                            setCurrentUser((prev) => ({
                                ...prev,
                                username: e.target.value,
                            }))
                        }
                        variant="outlined"
                        margin="dense"
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label={t("email")}
                        fullWidth
                        value={currentUser.email}
                        onChange={(e) =>
                            setCurrentUser((prev) => ({
                                ...prev,
                                email: e.target.value,
                            }))
                        }
                        variant="outlined"
                        margin="dense"
                    />
                    <FormControl fullWidth margin="dense" sx={{ mt: 2 }}>
                        <InputLabel id="role-select-label">{t("role")}</InputLabel>
                        <Select
                            labelId="role-select-label"
                            value={currentUser.role || ''}
                            onChange={(e) =>
                                setCurrentUser((prev) => ({
                                    ...prev,
                                    role: e.target.value,
                                }))
                            }
                            label={t("role")}
                        >
                            {rolesOptions.map((role) => (
                                <MenuItem key={role} value={role}>
                                    {role}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="secondary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleSaveUser} color="primary">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
